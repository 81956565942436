<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="onClickLeft('/')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
      {{$t('m.txjl')}}
      </div>
    </div>
   <div class="card">
               <van-list
                v-model="loading"
                :finished="finished"
                 loading-text="loading"
                finished-text=""
                @load="onLoad"
              >
              <div class="contents">
  <div v-for="(item, i) in list" :key="i" class="item">
                  <div class="lines">
                      <div class="left">                       
                        <span >
                          {{item.completeStatus==1?item.payStatus==2?$t('m.txcg'):item.payStatus==3?$t('m.bh'):$t('m.dsh'):$t('m.txsb')}}
                        </span>
                        </div>
                      <div class="time">{{item.createDate}}</div>
                  </div>
                  <div class="">
                      <div class="right">-{{item.amount||0}} DA</div>
                  </div>
                  
                </div>
                 <div v-if="list.length==0"  class="noData">
                  <img src="../assets/img/logo.png" alt="" >
                  <div class="ttx">DA</div>
                </div>

              </div>
              </van-list>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      type:0,
      list: [],
      lists:[],
      loading: false,
      finished: false,
      refreshing: false,
      tablist: [],
      page: 1,
      menuShow:false
    };
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.onClickLeft('/')
    },
    deep: true,
    immediate: true,
  },
   computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    }
  },
  methods: {
      to(url) {
      location.href = url;
    },
     onClickLeft(val) {
       this.$router.replace({ path:val})
    },
 menu() {
      this.menuShow = true;
    },
       gbsmenu() {
      this.menuShow = false;
    },
     goto(val) {
      this.$router.push({ path: val });
    },
      changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },

    onClick(e) {
      console.log(e);
      this.page = 1;
      this.loading = true;
      this.finished = false;
      setTimeout(() => {
        this.refreshing = true;
        this.onLoad();
      }, 100);
    },
    async onLoad() {    
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      // this.list = [{address:''}];
      let {data: { code, data, message },} = await this.$http.get(this.$api.getHisList,{
        pageNum : this.page,
          pageSize :10,
          uid: this.$store.getters.myID
      });
      if(code==200){
        this.loading = false;
              data.map(el=>{
                el.show=false
                return el
              })
              if (this.page > 1) {
                this.list = [...this.list, ...data];
              } else {
                this.list = data;
              }
              if (data.length >= 10) {
                this.page++;
              } else {
                this.finished = true;
              }
      }      
 
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.page=1
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.main {
  height: 100%;
  min-height: 100vh;
  width: 750px;
  margin: 0 auto;
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  background-size:contain;
  background-position: center bottom;
        position: relative;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
    .van-list{
  }
 .top {
background: rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
      .back {
      position: absolute;
    left: 20px;
    width: 50px;
    height: 50px;
    // background: linear-gradient(45deg,#e4005e, #ff0050);
    // border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title{
        background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
      color: #fff;
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
 .mdex{
    font-size: 24px;
    color: #fff;
    background: rgba(0 0 0 / 24%);
    margin: 0 0 20px;
    padding: 20px;
    border-top:1px solid  #6d6d6d
  }
  
  .card {
    border-radius: 20px;
    padding: 20px;
  }
  .van-tabs {
    ::v-deep.van-tabs__wrap {
      height: 80px;
    }
    ::v-deep.van-tab {
      line-height: 44px;
      font-size: 32px;
    }
    ::v-deep.van-tab__pane-wrapper {
      height: 100%;
    }
  }
    .line {
      height: 8px;
      background: #f7f7fa;
    }
    .content{
      width: 692px;
      border-radius: 20px;
      margin: 26px auto;
      padding:20px 0;

    
    }
    .contents{     
      border-radius: 20px;
      margin: 26px 10px;
      padding: 0 0 28px ;
       .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      .ltxt {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 48px;
      }   
    }
     .ttxs {
      font-size: 30px;
      font-family: $t-mf;
      font-weight: 400;
      color: #0d0c0c;
      line-height: 44px;
      text-align: left;
      margin: 11px 0 11px;
    }
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0px;
      background: #00000070;
      border: 2px solid #735f24;
    border-radius: 20px ;
    padding: 10px 20px;
   .ttxt{
     font-size: 30px;
font-family:$t-mf;
font-weight: 600;
color: #999999;
line-height: 34px;
   }
      .lines{
        display: flex;
      justify-content: space-between;
        align-items: flex-start; 
        flex-direction: column; 
          margin: 0px 30px 10px 0;
          flex: 1;
      .left {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        margin: 20px 0;
        text-align: left;
      }
      .bl{
        color: #ff0050;
        margin: 0;
      }
      .time{
        font-size: 24px;
font-family: $t-mf;
font-weight: bold;
color: #a9a5a6;
line-height: 28px;
      }
      .right {
        font-size: 34px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 32px;
      }
      .red{
        color: red;
      }
      .blue{
        color: green;
      }
      }
      .right{
         font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 32px;
      }
    }
  }
  .mentop {
    display: flex;
    justify-content: space-between;
    margin: 44px 30px;
    .logo {
      // height: 82px;
      width: 232px;
    }
    .gb {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }
  }
  .menulist {
    margin-top: 100px;
    .menuitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 44px;
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 30px;
      .left {
        flex: 1;
        text-align: left;
        display: flex;
        img {
          height: 48px;
          width: 48px;
        }
        .txt {
          margin-left: 40px;
          font-size: 36px;
          font-family: $t-mf;
          font-weight: 600;
         color: #000;
          line-height: 50px;
        }
      }
      .right {
        img {
          height: 38px;
          width: 26px;
        }
      }
    }
      .act{
       .txt {
         color: #2f73fd !important;
       }
    }
  }
  .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 0;
      padding-bottom: 80px;
      width: 100%;
      // position: absolute;
      // bottom: 0;
      // left: 50%;
      // transform: translateX(-50%);
    }
    .noData{
		min-height: 70vh;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		img{
			width: 342px;
			margin: 50px auto;
		}
    .ttx{
      font-size:32px;
      color: #ffffff;
  font-family: $t-f;
   font-weight: bold;
    }
	}

</style>